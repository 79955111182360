import { useState, useEffect, PropsWithChildren } from 'react'
import { InView } from 'react-intersection-observer'
import { internLog } from "../lib/Util"
import { selectCurrentUser } from "../renderer/currentUserSlice"
import { useAppSelector } from "../hooks"

interface InViewWrapperProps {
  skipOnView: boolean
  intervalTs: integer
  onInterval: () => void
  className: string
  rootMargin: string
  as: string
}

function InViewWrapper({ children, skipOnView, intervalTs, onInterval, className, rootMargin, as }: PropsWithChildren<InViewWrapperProps>) {
  const [requestTimeoutId, setRequestTimeoutId] = useState(null)
  const [originalSkipOnView, setOriginalSkipOnView] = useState(skipOnView)
  const currentUser = useAppSelector(selectCurrentUser)

  const dequeueRequest = (timeoutId, forced) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
      setRequestTimeoutId(null)
      internLog(currentUser, forced ? 'forced dequeue' : 'dequeue')
    }
  }

  if (originalSkipOnView) {
    if (!skipOnView) {
      setOriginalSkipOnView(false)
    }
  } else {
    if (skipOnView) {
      dequeueRequest(requestTimeoutId, true)
      setOriginalSkipOnView(skipOnView)
    }
  }

  const onViewChange = async (inView, _entry) => {
    if (inView && !skipOnView && !requestTimeoutId) {
      const intervalId = setTimeout(() => {
        setRequestTimeoutId(null)
        onInterval()
      }, intervalTs)
      internLog(currentUser, 'enqueue', intervalTs)

      setRequestTimeoutId(intervalId)
    } else {
       // possible race condition, because interval might get cleared before we call to clear it
      dequeueRequest(requestTimeoutId)
    }
  }

  useEffect(() => {
    return () => {
      dequeueRequest(requestTimeoutId)
    }
  }, [])

  return(
    <InView as={as || "span"}
            onChange={onViewChange}
            skip={skipOnView}
            rootMargin={rootMargin}
            trackVisibility={true}
            delay="100"
            className={className}>
      { children }
    </InView>
  )
}

export default InViewWrapper
